import React from 'react';
import logo from './logo.svg';
import './App.css';
import firebase from './Firebase';
import ReactDOM from 'react-dom';

const newsletters = firebase.firestore().collection('newsletters');

function App() {
    let news = [];
    newsletters.get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                news.push(doc.id);
                   tick(doc.id)
            });
            return news;
        });
    let message = 'Bonjour' + news.join(" #");

    return (
        <div className="App">

            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    {message} Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

function tick(id) {
    const element = (
        <div>
            <h1>Hello, world!</h1>
            <h2>It is {id}.</h2>
        </div>
    );
    //ReactDOM.render(element, document.getElementById('bon'));
}

export default App;
