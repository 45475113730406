import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const settings = {};

const firebaseConfig = {
  apiKey: "AIzaSyB2EI4YOVMCddbk_lqPxV37LNsGlrLaW2Y",
  authDomain: "naturalhappy-malam.firebaseapp.com",
  projectId: "naturalhappy-malam",
  storageBucket: "naturalhappy-malam.appspot.com",
  messagingSenderId: "249877949177",
  appId: "1:249877949177:web:2e73218d300f5e92d69028"
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings(settings);

export default firebase;
